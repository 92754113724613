import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { HelpersService } from "../helper/helpers.service";
import { ProjectService } from "../project/project.service";

import { ProfileUser, User } from "app/shared/models/user.interface";
import { AlertError } from "../error-handler";

@Injectable({
	providedIn: "root",
})
export class UserService {
	constructor(
		private http: HttpClient,
		public router: Router,
		private authSvc: AuthService,
		private projectSvc: ProjectService,
		private helpersSvc: HelpersService
	) {}

	getLanguageUser(): string {
		const languageUser = localStorage.getItem("languageUser");
		return languageUser ? languageUser : "es";
	}

	private saveLanguageUser(language): void {
		localStorage.setItem("languageUser", language);
	}

	verifySecurityCode(securityCode: string) {
		return new Observable<boolean>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const body = {
				security_code: securityCode,
			};

			observer.next(true);

			//TODO
			// this.http.post(environment.urlDomain + '/api/v1/user/securityCode', body, options).subscribe(
			// 	(response: any) => {
			// 		observer.next(true);
			// 	},
			// 	err => {
			// 		console.log("error login", err);
			// 		let msgError = "Se ha producido un error";
			// 		// if (err.error && err.error.code) {
			// 		// 	const codeError = err.error.code;
			// 		// 	if (codeError === 'L001' || codeError === 'L002') {
			// 		// 		msgError = "Usuario o contraseña incorrectos";
			// 		// 	}
			// 		// }

			// 		observer.error(msgError);
			// 	}
			// )
		});
	}

	checkMustChangePassword(): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			this.http.get(environment.urlDomain + "/api/v1/user/me", options).subscribe(
				(response: User) => {
					// console.log("get mustChangePassword", response);
					if (response.mustChangePassword === 0) {
						observer.next(true);
					} else {
						const urlPassword = response.urlPassword;
						this.router.navigate([urlPassword]);
						observer.next(false);
					}
				},
				(err) => {
					this.router.navigate(["login"], { replaceUrl: true, queryParams: { returnUrl: this.router.url } });
					observer.next(false);
				}
			);
		});
	}

	getUser(): Observable<User> {
		return new Observable<User>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			this.http.get(environment.urlDomain + "/api/v1/user/me", options).subscribe(
				(response: any) => {
					//console.log("get user", response);
					this.saveLanguageUser(response.language);
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDataUser() {
		const accessToken = this.authSvc.getAccessToken();

		let headers = new HttpHeaders({
			Authentication: "Bearer " + accessToken,
			"Content-Type": "application/json",
		});

		return this.http.get<any>(environment.urlDomain + "/api/v1/user/me", { headers: headers }).pipe(
			tap((user) => {
				return user;
			})
		);
	}

	updateUser(data: any): Observable<boolean> {
		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + this.authSvc.getAccessToken(),
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const body = { ...data, legal: 1 };

		console.log("updateUser", body);

		return new Observable<boolean>((observer) => {
			this.http.put(environment.urlDomain + "/api/v1/user/me", body, options).subscribe(
				(data) => {
					observer.next(true);
				},
				(err) => {
					AlertError.showError(err);
					observer.error(err.error.message);
				}
			);
		});
	}

	modifyDataUser(nombre: string, apellidos: string, fecha: string, email: string, password: string, legal: number) {
		const accessToken = this.authSvc.getAccessToken();

		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const body = {
			firstname: nombre,
			lastname: apellidos,
			birthday: fecha,
			email: email,
			password: password,
			legal: legal,
		};

		return new Observable<any>((observer) => {
			return this.http.put(environment.urlDomain + "/api/v1/user/me", body, options).subscribe(
				(data) => {
					this.router.navigate(["/inicio"], { replaceUrl: true });
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	changePassword(newPassword: string): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				password: newPassword,
			};

			this.http.put(environment.urlDomain + "/api/v1/user/config/changePassword", body, options).subscribe(
				(response: any) => {
					console.log("change password", response);
					observer.next(true);
				},
				(err) => {
					observer.error(err.error.message);
				}
			);
		});
	}

	profile(): Observable<ProfileUser> {
		return new Observable<ProfileUser>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			this.http.get(environment.urlDomain + "/api/v1/user/profile", options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	saveClima(clima: number, motivo: string): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				value: clima,
				motivation: motivo,
			};

			this.http.post(environment.urlDomain + "/api/v1/clima", body, options).subscribe(
				(response: any) => {
					observer.next(true);
				},
				(err: any) => {
					AlertError.showError(err);
				}
			);
		});
	}

	firmaInfo(): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			this.http.get(environment.urlDomain + "/api/v1/user/firmaInfo", options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	changeLanguage(newLang: string): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				language: newLang,
			};

			this.http.put(environment.urlDomain + "/api/v1/user/config/changeLanguage", body, options).subscribe(
				(response: any) => {
					console.log("change language", response);
					observer.next(true);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	changeImage(image: any): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				image: {
					filename: "imagenperfil.png",
					base64: image,
				},
			};

			this.http.post(environment.urlDomain + "/api/v1/user/config/changeImage", body, options).subscribe(
				(response: any) => {
					observer.next(true);
				},
				(err: any) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getTransactions(numPage): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
				}),
			};

			const params = {
				page: numPage,
				pageSize: environment.pageSize,
			};

			this.http.get(environment.urlDomain + "/api/v1/transactions/?" + this.helpersSvc.serializeData(params), options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	loginReadOnlyMode(personId: string) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/user/loginReadOnly";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	enviarIncidenciaSimple(asunto: string, cuerpo: string, captura: any, urlActual: string): Observable<any> {
		const accessToken = this.authSvc.getAccessToken();
		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
			}),
		};

		const url = `${environment.urlDomain}/api/v1/util/registrarIncidenciaSimple`;
		return this.http.post(url, { asunto: asunto, cuerpo: cuerpo, captura: captura, url: urlActual }, options);
	}

	getAuthQr(person: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId: person,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/qr2FA/getQR";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	validar2FA(token, personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/qr2FA/check";
			this.http.post(url, { token, personId }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	mailCodeVerification(token, personId): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/mailCodeVerification/check";
			this.http.post(url, { token, personId }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getBankFamily(person: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: new HttpParams({
					fromObject: {
						personId: person,
					},
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/bankfamily/getData";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
}
